const Parse = require("ParseInit");

const Transaction = Parse.Object.extend("transaction", {
    col: {
        ACL: "ACL",
        address:"address",
        createdAt: "createdAt",
        currency: "currency",
        tax: "tax",
        taxConfig: "taxConfig",
        contactMail: "contactEmail",
        externalTransactionId: "externalTransactionId",
        objectId: "objectId",
        parcoursArticles: "articlePositions",
        managementID: "managementID",
        price: "price",
        discount: "discount",
        status: "status",
        controlStatus: "controlStatus",
        parcoursID: "parcoursID",
        invoiceNumber: "invoiceNumber",
        invoiceDate: "invoiceDate",
        invoice: "invoice",
        parcoursOwnerID: "parcoursOwnerID",
        paymentCode: "paymentCode",
        tournamentUsers: "tournamentUsers",
        tournamentID: "tournamentID",
        clubInvoiceID: "clubInvoiceID",
        brand: "brand",
        surcharge: "surcharge",
        processingStatus: "processingStatus",
        processingReason: "processingReason", // message for customer
        processingReturn: "processingReturn", // message for merchant
        processingReturnCode: "processingReturnCode",
        refTransaction: "refTransaction",
        updatedAt: "updatedAt",
        afterPayment: "afterPayment",
        emailSuccess: "emailSuccess",
        voucherID: "voucherID",
        parcoursVisitID: "parcoursVisitID",
        userID: "userID",
        allowPending: "allowPending"
    },
    payStatus: {
        init: "INIT",
        pending: "PENDING",
        ack: "ACK",
        nok: "NOK"
    },
    afterPay : {
        userAbo: "userAbo"
    },
    getTransactionDate() {
        var tDate = this.getCreatedAt();
        return tDate.getDate() + "." + (tDate.getMonth() + 1) + " " + tDate.getFullYear();
    },
    getTransactionTime() {
        // TODO check timezone maybe hours are not correct
        var tDate = this.getCreatedAt();
        return tDate.getHours() + ":" + tDate.getMinutes() + ":" + tDate.getSeconds();
    },
    getCompany() {
        return null;
    },
    getFullName() {
        const address = this.get(this.col.address);
        if (address) {
            return address.name + " " +  address.surname;
        } else {
            const resObject = this.get(this.col.pspResultObj);
            if (resObject) {
                return resObject["NAME_GIVEN"] + " " + resObject["NAME_FAMILY"];
            }
        }
        return "-";
    },
    getStreet() {
        const address = this.get(this.col.address);
        if (address) {
            return address.street;
        }
    },
    getZipPlace() {
        const address = this.get(this.col.address);
        if (address) {
            return address.zip + " " +  address.place;
        } else {
            const resObject = this.get(this.col.pspResultObj);
            if (resObject) {
                return resObject["ADDRESS_CITY"];
            }
        }
        return "-";
    },
    getContactMail: function () {
        return this.get(this.col.contactMail);
    },
    getPayerRefId() {
        const address = this.get(this.col.address);
        if (address) {
            return address.extRefID;
        }
        return null;
    },
    getPaymentCode() {
        return this.get(this.col.paymentCode);
    },
    getArticlesAmount() {
        let articles = this.getParcoursArticles();
        if (articles) {
            return articles.length;
        }
        return 0;
    },
    getFormattedPrice() {
        return this.getCurrency() + " " + parseFloat(this.getFullPrice()).toFixed(2);
    },
    getFormattedSurcharge() {
        return this.getCurrency() + " " + parseFloat(this.getSurcharge()).toFixed(2);
    },
    getFullPrice() {
        const surcharge = this.getSurcharge();
        if (surcharge) {
            return this.getPrice() + this.getSurcharge();
        } else {
            return this.getPrice();
        }

    },
    showInTransaction() {
        if (this.isStatusACK()) {
            return true;
        } else if (this.getClubInvoiceID() && this.isStatusPending()) {
            return true;
        }
        return false;
    },
    isStatusACK() {
        return this.payStatus.ack === this.getStatus();
    },
    isStatusNOK() {
        return this.payStatus.nok === this.getStatus();
    },
    isStatusPending() {
        return this.payStatus.pending === this.getStatus();
    },
    isAllowPending() {
        return this.get(this.col.allowPending)
    },
    getACL() {
        return this.get(this.col.ACL)
    },
    setACL(ACL) {
        return this.set(this.col.ACL, ACL)
    },
    getCreatedAt() {
        return this.get(this.col.createdAt)
    },
    getCurrency() {
        return this.get(this.col.currency)
    },
    getBrand() {
        return this.get(this.col.brand)
    },
    isStornoPossible() {
        // need invoice, ACK status
        return this.getInvoiceNumber() != null && (this.isStatusACK() || this.isStatusPending())
            // not an invoice, not a storno
            && this.getBrand() !== "INVOICE" && !this.isStornoBrand()
            // has no referenced Transaction
            && this.getReferencedTransaction() == null;
    },
    isStornoBrand() {
        return this.getBrand() === "STORNO";
    },
    setCurrency(currency) {
        return this.set(this.col.currency, currency)
    },
    getTax() {
        return this.get(this.col.tax)
    },
    getTaxConfig() {
        return this.get(this.col.taxConfig)
    },
    setTax(tax) {
        return this.set(this.col.tax, tax);
    },
    getExternalTransactionId() {
        return this.get(this.col.externalTransactionId)
    },
    setExternalTransactionId(externalTransactionId) {
        return this.set(this.col.externalTransactionId, externalTransactionId)
    },
    getObjectId() {
        return this.get(this.col.objectId)
    },
    setObjectId(objectId) {
        return this.set(this.col.objectId, objectId)
    },
    getVoucherID() {
        return this.get(this.col.voucherID);
    },
    getParcoursArticles() {
        return this.get(this.col.parcoursArticles)
    },
    setParcoursArticles(parcoursArticles) {
        return this.set(this.col.parcoursArticles, parcoursArticles)
    },
    getPrice() {
        return this.get(this.col.price)
    },
    getBruttoPrice() {
        return this.getPrice()
    },
    getExtraRowPrice() {
        return this.getSurcharge()
    },
    getSurcharge() {
        return this.get(this.col.surcharge)
    },
    getDiscount() {
        return this.get(this.col.discount)
    },
    getFormattedDiscount() {
        return this.getCurrency() + " " + parseFloat(this.getDiscount()).toFixed(2);
    },
    setPrice(price) {
        return this.set(this.col.price, price)
    },
    isUserAboTransaction() {
        const afterPayment = this.getAfterPayment();
        return afterPayment && afterPayment[this.afterPay.userAbo] == true;
    },
    getAfterPayment() {
        return this.get(this.col.afterPayment);
    },
    getManagementID() {
        return this.get(this.col.managementID);
    },
    getProcessingStatus() {
        return this.get(this.col.processingStatus);
    },
    getProcessingReturn() {
        return this.get(this.col.processingReturn);
    },
    getProcessingReason() {
        return this.get(this.col.processingReason);
    },
    getProcessingReturnCode() {
        return this.get(this.col.processingReturnCode);
    },
    getClubInvoiceID() {
        return this.get(this.col.clubInvoiceID)
    },
    getInvoiceNumber() {
        return this.get(this.col.invoiceNumber)
    },
    getInvoice() {
        return this.get(this.col.invoice)
    },
    getInvoiceDate() {
        return this.get(this.col.invoiceDate)
    },
    getStatus() {
        return this.get(this.col.status)
    },
    setStatus(status) {
        return this.set(this.col.status, status)
    },
    getParcoursOwnerID() {
        return this.get(this.col.parcoursOwnerID);
    },
    getParcours() {
        return this.get(this.col.parcoursID);
    },
    getReferencedTransaction() {
        return this.get(this.col.refTransaction);
    },
    getTournamentUsers() {
        return this.get(this.col.tournamentUsers)
    },
    setTournamentUsers(tournamentUsers) {
        return this.set(this.col.tournamentUsers, tournamentUsers)
    },
    getTournamentID() {
        return this.get(this.col.tournamentID)
    },
    getUpdatedAt() {
        return this.get(this.col.updatedAt)
    },
    getUserID() {
        return this.get(this.col.userID)
    },
    setUserID(userID) {
        return this.set(this.col.userID, userID)
    },
});

module.exports = Transaction;
