const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col,Grid, Alert, Button} = require("react-bootstrap");

const I18n = require("components/widgets/I18n"),
    Loading = require("components/widgets/Loading"),
    FontAwesome = require("components/widgets/FontAwesome"),
    SubmitButton = require("components/form/SubmitButton"),
    TransactionDetailLink = require("components/links/TransactionDetailLink"),
    CCLogTable= require("components/payment/CCLogTable"),
    TransactionDetailTable= require("components/payment/TransactionDetailTable"),
    ExtLink = require("components/links/ExtLink"),
    linkUtil = require("linkUtil"),
    messages = require("i18n/messages");

const ParcoursLink = require("components/parcours/ParcoursLink"),
    TournamentLink = require("components/tournament/TournamentLink");

const userStore = require("stores/UserStore"),
    userActions = require("actions/UserActions"),
    transactionStore = require("stores/TransactionStore"),
    transactionActions = require("actions/TransactionActions");

class TransactionDetailPage extends React.Component {
    constructor(props) {
        super(props);
        this.handleUserChange = this.handleUserChange.bind(this);
        this.handleTransactionChange = this.handleTransactionChange.bind(this);

        this.state = {
            userStore: userStore.getState(),
            transactionStore: transactionStore.getState()
        }
    }
    componentDidMount() {
        userStore.listen(this.handleUserChange);
        transactionStore.listen(this.handleTransactionChange);
        transactionActions.loadTransaction(this.props.transactionID, "admin" == this.props.tstat);
    }
    componentWillUnmount() {
        userStore.unlisten(this.handleUserChange);
        transactionStore.unlisten(this.handleTransactionChange);
    }
    handleUserChange(state) {
        this.setState({
            userStore: state
        });
    }
    handleTransactionChange(state) {
        this.setState({
            transactionStore: state,
        });
    }
    render() {
        const {tstat} = this.props
        let title = tstat ? messages.get("transaction.detail.header." + tstat) : null;
        return (
                <Grid>
                    <Row>
                        <Col xs={12} md={8} mdOffset={2} style={{"textAlign": "center"}}>
                            <h3>{title}</h3>
                        </Col>
                    </Row>
                    {this.state.transactionStore.loadingT ? <Loading/> : ""}
                    {!this.state.transactionStore.loadingT && this.state.transactionStore.transaction == null ?
                        <Alert bsStyle="warning"><I18n code="transaction.detail.notFound"/></Alert> : ""
                    }
                    {!this.state.transactionStore.loadingT && this.state.transactionStore.transaction != null ?
                        <TransactionDetail transaction={this.state.transactionStore.transaction}
                                           ccLogs={this.state.transactionStore.ccLogs}
                                           pendingInvoice={"pendingInvoice" === tstat}
                                           pendingTransaction={"checkoutPending" === tstat}
                                           tstat={tstat}
                                           isAdmin={this.state.userStore.isAdmin}/>: ""
                    }
                </Grid>
        )
    }
}
TransactionDetailPage.propTypes = {
    transactionID: PropTypes.string.isRequired,
    tstat: PropTypes.string,
};
const TransactionDetail = ({transaction, ccLogs, tstat, pendingInvoice, pendingTransaction, isAdmin}) => {
    function onClick() {
        transactionActions.generateInvoice(transaction.id);
    }
    function getInvoice() {
        if (transaction.getInvoiceNumber()) {
            if (transaction.getInvoice()) {
                return (<a target="_BLANK" href={transaction.getInvoice()._url}>
                    <FontAwesome icon="download"/>
                    {messages.get("transaction.detail.link.invoice")}: {transaction.getInvoiceNumber()}
                </a>);
            } else if (isAdmin){
                // generate invoice button
                return <Button onClick={onClick}>
                    <FontAwesome icon="plus-circle"/>{transaction.getInvoiceNumber()}
                </Button>;
            }
        }
        return "";
    }
    if (typeof transaction.getParcoursArticles === "function") {
        const positions = transaction.getParcoursArticles();
        return (
            <Row>
                <Col xs={12} md={8} mdOffset={2} style={{"textAlign": "center"}}>
                    {
                        transaction.getParcours() && !transaction.isUserAboTransaction() ?
                            <p><ParcoursLink parcours={transaction.getParcours()}
                                             label={<FontAwesome icon="map-marker-alt">{messages.get("transaction.detail.link.parcours")}</FontAwesome>}/>
                            </p> : null
                    }
                    {
                        transaction.getTournamentID() ?
                            <p><TournamentLink tournament={transaction.getTournamentID()}
                                               label={<FontAwesome icon="external-link-alt">{messages.get("transaction.detail.link.tournament")}</FontAwesome>}/>

                            </p> : null
                    }

                    <p>{getInvoice()}</p>
                    {
                        transaction.getVoucherID() != null && transaction.getVoucherID().getVoucherFile() != null ?
                            <p><a target="_BLANK" href={transaction.getVoucherID().getVoucherFile()._url}>
                                <FontAwesome icon="download"/>{messages.get("transaction.detail.link.voucher")}
                                </a></p> : null
                    }
                    {
                        transaction.isStatusACK() ? <p><I18n code="transaction.detail.mail.invoice"/></p>: null
                    }
                    <hr/>
                </Col>
                {
                    transaction.isStatusACK() || pendingInvoice === true ?
                        <TransactionDetailTable transaction={transaction} positions={positions}/>
                        : null

                }
                {
                    pendingTransaction ? <TransactionPending transaction={transaction}/> : null
                }
                {
                    tstat == "admin" || (!transaction.isStatusACK() && pendingInvoice === false && pendingTransaction === false) ?
                        <TransactionDetailError transaction={transaction} ccLogs={ccLogs} isAdmin={isAdmin}/>
                        : null
                }
            </Row>)
    } else {
        return null;
    }
};
TransactionDetail.propTypes = {
    transaction:PropTypes.object.isRequired,
    ccLogs: PropTypes.array,
    tstat: PropTypes.string.isRequired,
    pendingInvoice: PropTypes.bool.isRequired,
    isAdmin:PropTypes.bool
};
class TransactionPending extends React.Component {
    componentDidMount() {
        // check again if payment is done
        if (this.props.transaction.isAllowPending()) {
            setTimeout(() => {
                this.notifyPaymentPending(this.props.transaction.id)
            }, 5000);
        }
    }
    notifyPaymentPending(transactionID) {
        transactionActions.notifyPaymentPending.defer(transactionID);
    }
    render() {
        return (<Col xs={12} md={8} mdOffset={2} style={{"textAlign": "center"}}>
            <Loading />
            <h3><I18n code="transaction.detail.pending.info"/></h3>
        </Col>)
    }
}
class TransactionDetailError extends React.Component {
    constructor(props) {
        super(props);
        this.handleInitTransaction = this.handleInitTransaction.bind(this);
        this.getCheckoutRestart = this.getCheckoutRestart.bind(this);
        this.state = {}
    }
    componentDidMount() {
        // start check if admin
        userActions.getIsAdmin.defer();
    }
    handleInitTransaction() {
        transactionActions.initTransaction(this.props.transaction.id)
    }
    getCheckoutRestart(transaction) {
        if (transaction.isStatusPending()) {
            if (transaction.getPaymentCode() == "VA.DB" || transaction.getPaymentCode() == "BB.DB") {
                return  <a href={linkUtil.getLink("transaction/" + transaction.id + "/calling")}>{messages.get("transaction.detail.restart")}</a>
            } else {
                // TODO PENDING, transaction is not too old, user fits
                return <Button bsStyle="primary" onClick={this.handleInitTransaction}>{messages.get("transaction.detail.restart")}</Button>
            }
        }
        return null;
    }
    render() {
        const {transaction, ccLogs, isAdmin} = this.props;
        return (<Col xs={12} md={8} mdOffset={2} style={{"textAlign": "center"}}>
            <p>{messages.get("transaction.detail.error." + transaction.getStatus())}</p>
            <br/>
            <Alert bsStyle="warning">
                {
                    transaction.getProcessingReturnCode() != null ? <h4><strong>{messages.get("parse.error.expected")}: </strong>{transaction.getProcessingReturnCode()}</h4>: null
                }
                <div>
                    {
                        transaction.getProcessingReturn() != null ? <strong><I18n code={transaction.getProcessingReturn()}/></strong>: null
                    }
                    <br/>
                    {
                        transaction.getProcessingReason() != null ? <strong>{transaction.getProcessingReason()}</strong>: null
                    }
                    <br/><br/>
                    <ExtLink href="https://3dturnier.freshdesk.com/support/solutions/folders/42000110894">
                        <I18n code="transaction.detail.link.freshdesk"/>
                    </ExtLink>
                </div>
            </Alert>



            {
                this.getCheckoutRestart(transaction)
            }
            <hr/>
            {
                isAdmin ? <TransactionAddress transaction={transaction} />: null
            }
            {
                isAdmin ? <TransactionInvoiceStorno transaction={transaction}/> : null
            }
            {
                isAdmin ? <TransactionOverrideNotPaid transaction={transaction}/> : null
            }
            <hr/>
            {
                ccLogs != null ? <CCLogTable header="Cloud Code Log Entries" showTrace={true} showError={true} ccLogs={ccLogs}/> : null
            }
        </Col>)
    }
}
TransactionDetailError.propTypes = {
    transaction:PropTypes.object.isRequired,
    isAdmin:PropTypes.bool,
    ccLogs: PropTypes.array
};
const TransactionAddress = ({transaction}) => {
    return <p>{transaction.getFullName()}<br/>{transaction.getStreet()}<br/>{transaction.getZipPlace()}<br/>{transaction.getContactMail()}</p>
}

const TransactionOverrideNotPaid = ({transaction}) => {
    function onClickManCheckout() {
        transactionActions.manualCheckout(transaction.id);
    }
    if (transaction.isStatusPending() || transaction.isStatusNOK()) {
        return <React.Fragment>
            <hr/>
            <h3>Transaction Override Paid</h3>
            <SubmitButton bsStyle="danger" onClick={onClickManCheckout} text="Override Paid" icon={<FontAwesome icon="plus-circle"/>}/>
        </React.Fragment>
    }
    return null
}

const TransactionInvoiceStorno = ({transaction}) => {
    function handleInitTransaction() {
        transactionActions.stornoTransaction(transaction.id)
    }
    return <React.Fragment>
        <hr/>
        <h3>Transaction Storno</h3>
        {
            transaction.isStornoPossible() ? <SubmitButton bsStyle="warning" onClick={handleInitTransaction} text="Generate storno-invoice" /> : null

        }
        {
            transaction.getReferencedTransaction() != null && transaction.isStornoBrand() ?
                <p><TransactionDetailLink transactionID={transaction.getReferencedTransaction().id} label={"ORIGIN: " + transaction.getReferencedTransaction().id}/></p> : null
        }
        {
            transaction.getReferencedTransaction() != null && !transaction.isStornoBrand() ?
                <p><TransactionDetailLink transactionID={transaction.getReferencedTransaction().id} label={"STORNO: " + transaction.getReferencedTransaction().id}/></p> : null
        }
    </React.Fragment>;
}
TransactionInvoiceStorno.propTypes = {
    transaction:PropTypes.object.isRequired
};
module.exports = TransactionDetailPage;
