const Parse = require("ParseInit");
const ConfigKeys = require("ConfigKeys");
const React = require("react");
const ReactDOM = require('react-dom');
const ReactGA = require("react-ga");
const { AppContainer } = require( 'react-hot-loader');
const {Row, Col, Grid, Image, Navbar} = require("react-bootstrap");
const FontAwesome = require("components/widgets/FontAwesome");
const FontAwesomeLib = require("FontAwesomeLib");
const LoadingCircle = require("components/widgets/LoadingCircle");

const {User} = require("parse/_Domain");

const notificationActions = require("components/notification/NotificationActions");

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";

import CookieConsent from "react-cookie-consent";
import bootstrap from './../css/bootstrap.min.css'
import modern from './../css/modern-business.css'
import reactRange from './../css/react-range.css'
// main components
//
var MenuWrapper = require("components/MenuWrapper"),
    FrontPage = require("components/home/FrontPage"),
    NewsPage = require("components/home/NewsPage"),
    ClubPage = require("components/club/ClubPage"),
    ClubDetailsPage = require("components/club/ClubDetailsPage"),
    ClubSettingsPage = require("components/club/ClubSettingsPage"),
    ClubInvoicePage = require("components/clubaccounting/ClubInvoicePage"),
    ClubAboInvoicePage = require("components/clubaccounting/ClubAboInvoicePage"),
    ClubInvoiceCheckoutPage = require("components/payment/ClubInvoiceCheckoutPage"),
    ClubEventPage = require("components/club/ClubEventPage"),
    ClubUsersPage = require("components/club/ClubUsersPage"),
    ClubUserDetailPage = require("components/club/ClubUserDetailPage"),
    ClubAccountingPage = require("components/clubaccounting/ClubAccountingPage"),
    ClubArticlePage  = require("components/club/ClubArticlePage"),
    TournamentPage = require("components/tournament/TournamentPage"),
    TournamentRulePage = require("components/tournament/TournamentRulePage"),
    TournamentRegistrationPage = require("components/tournamentregister/TournamentRegistrationPage"),
    TournamentLivePage = require("components/tournament/TournamentLivePage"),
    TournamentLiveGroupPage = require("components/tournament/TournamentLiveGroupPage"),
    TournamentDetailsPage = require("components/tournament/TournamentDetailsPage"),
    TournamentCupUserLinkPage = require("components/tournament/TournamentCupUserLinkPage"),
    TournamentCheckoutPage = require("components/payment/TournamentCheckoutPage"),
    TournamentManagerPage = require("components/tournamentmanage/TournamentManagerPage"),
    TournamentAdminPage = require("components/tournamentmanage/TournamentAdminPage"),
    TournamentMMFilePage = require("components/tournamentmanage/TournamentMMFilePage"),
    TournamentMMRoundPage = require("components/tournamentmanage/TournamentMMRoundPage"),
    TournamentMMTUserPage = require("components/tournamentmanage/TournamentMMTUserPage"),
    TournamentMMTCorrectPage = require("components/tournamentmanage/TournamentMMTCorrectPage"),
    TournamentMMScanPage = require("components/tournamentmanage/TournamentMMScanPage"),
    TournamentMMConfigPage = require("components/tournamentmanage/TournamentMMConfigPage"),
    TrainingMainPage = require("components/training/TrainingMainPage"),
    TrainingRemotePage = require("components/training/TrainingRemotePage"),
    TrainingScatterPage = require("components/training/TrainingScatterPage"),
    SkillBoardPage = require("components/skillboard/SkillBoardPage"),
    SkillBoardProPage = require("components/skillboard/SkillBoardProPage"),
    SkillBoardProBuyPage = require("components/skillboard/SkillBoardProBuyPage"),
    GettingStarted = require("components/skillboard/GettingStarted"),
    JoinTournament = require("components/skillboard/JoinTournament"),
    CountTypesTab = require("components/skillboard/CountTypesTab"),
    FAQs = require("components/skillboard/FAQs"),
    ReleaseNotes = require("components/skillboard/ReleaseNotes"),
    YTVideos = require("components/skillboard/YTVideos"),
    ParcoursPage = require("components/parcours/ParcoursPage"),
    ParcoursPaymentPage = require("components/parcours/ParcoursPaymentPage"),
    ParcoursDetailPage = require("components/parcours/ParcoursDetailPage"),
    ParcoursAdminPage = require("components/parcours/ParcoursAdminPage"),
    ParcoursEditPage = require("components/parcours/ParcoursEditPage"),
    ParcoursVisitPage = require("components/parcours/ParcoursVisitPage"),
    ParcoursManageRequestPage = require("components/parcoursmanage/ParcoursManageRequestPage"),
    ParcoursArticlePage = require("components/parcoursmanage/ParcoursArticlePage"),
    ParcoursVisitListPage = require("components/parcoursmanage/ParcoursVisitListPage"),
    ParcoursLandingPage = require("components/parcoursmanage/ParcoursLandingPage"),
    ParcoursRankingPage = require("components/parcours/ParcoursRankingPage"),
    ParcoursTargetPage = require("components/parcours/ParcoursTargetPage"),
    ParcoursTargetTrackPage = require("components/parcoursmanage/ParcoursTargetTrackPage"),
    ParcoursTargetEditPage = require("components/parcoursmanage/ParcoursTargetEditPage"),
    ParcoursStatisticPage = require("components/parcoursmanage/ParcoursStatisticPage"),
    ParcoursCheckoutPage = require("components/payment/ParcoursCheckoutPage"),
    TargetCatalogPage = require("components/parcoursmanage/TargetCatalogPage"),
    ImageGalleryPage = require("components/home/ImageGalleryPage"),
    ContactPage = require("components/ContactPage"),
    SmartTournamentPage =  require("components/SmartTournamentPage"),
    TranslatorPage =  require("components/TranslatorPage"),
    PolicyPage = require("components/PolicyPage"),
    Impressum = require("components/Impressum"),
    AgbPage = require("components/AgbPage"),
    ParcoursDashboardPage = require("components/profile/ParcoursDashboardPage"),
    EventDetailPage = require("components/profile/EventDetailPage"),
    ProfileTabPage = require("components/profile/ProfileTabPage"),
    TabEventStatistic = require("components/profile/TabEventStatistic"),
    TabEventList = require("components/profile/TabEventList"),
    TabEventScatter = require("components/profile/TabEventScatter"),
    TabTournament = require("components/profile/TabTournament"),
    TabTransaction = require("components/profile/TabTransaction"),
    TabInformation = require("components/profile/TabInformation"),
    AccountPage = require("components/profile/AccountPage"),
    ResetPasswordPage = require("components/profile/ResetPasswordPage"),
    UserTokenPage = require("components/profile/UserTokenPage"),
    BusinessPromoPage = require("components/business/BusinessPromoPage"),
    BusinessRegisterPage = require("components/business/BusinessRegisterPage"),
    TransactionSettlementPage = require("components/business/TransactionSettlementPage"),
    TransactionDetailPage = require("components/payment/TransactionDetailPage"),
    SettlementDetailPage = require("components/payment/SettlementDetailPage"),
    NotFound = require("components/NotFound");

// admin packages
const AdminTabPage = require("components/admin/AdminTabPage"),
    AdminBusinessSettle = React.lazy(() => import(/* webpackChunkName: "admin" */ "components/admin/AdminBusinessSettle")),
    AdminBusinessHandler = React.lazy(() => import(/* webpackChunkName: "admin" */ "components/admin/AdminBusinessHandler")),
    AdminRelease = React.lazy(() => import(/* webpackChunkName: "admin" */ "components/admin/AdminRelease")),
    AdminPointInterest = React.lazy(() => import(/* webpackChunkName: "admin" */ "components/admin/AdminPointInterest")),
    AdminTournamentOptions = React.lazy(() => import(/* webpackChunkName: "admin" */ "components/admin/AdminTournamentOptions")),
    AdminChangeLog = React.lazy(() => import(/* webpackChunkName: "admin" */ "components/admin/AdminChangeLog")),
    AdminParseLog = React.lazy(() => import(/* webpackChunkName: "admin" */ "components/admin/AdminParseLog")),
    AdminUserSearch = React.lazy(() => import(/* webpackChunkName: "admin" */ "components/admin/AdminUserSearch")),
    AdminUserExchanger = React.lazy(() => import(/* webpackChunkName: "admin" */ "components/admin/AdminUserExchanger")),
    AdminParcoursExchanger = React.lazy(() => import(/* webpackChunkName: "admin" */ "components/admin/AdminParcoursExchanger")),
    AdminDataHandler = React.lazy(() => import(/* webpackChunkName: "admin" */ "components/admin/AdminDataHandler")),
    AdminStatisticHandler = React.lazy(() => import(/* webpackChunkName: "admin" */ "components/admin/AdminStatisticHandler")),
    AdminBusiness = React.lazy(() => import(/* webpackChunkName: "admin" */ "components/admin/AdminBusiness")),
    AdminBusinessRequest = React.lazy(() => import(/* webpackChunkName: "admin" */ "components/admin/AdminBusinessRequest")),
    AdminVoucher = React.lazy(() => import(/* webpackChunkName: "admin" */ "components/admin/AdminVoucher"));



const linkUtil = require("linkUtil");
const userActions = require("actions/UserActions");

// es6 fix
//

if (!String.prototype.startsWith) {
    String.prototype.startsWith = function (searchString, position) {
        position = position || 0;
        return this.indexOf(searchString, position) === position;
    };
}

// param parser
//
var getParam = (function () {
    var params = {};
    var keyValueStr = window.location.search.substr(1).split('&');
    for (var i = 0; i < keyValueStr.length; i++) {
        var keyValueArr = keyValueStr[i].split('=');
        params[decodeURIComponent(keyValueArr[0])] = decodeURIComponent(keyValueArr[1] || '');
    }
    return function (key) {
        return params[key];
    }
})();

/**
 * reset url by removing all query params. This is needed to not let users copy the session token.
 */
const resetURL = function () {
    if (window.history && window.history.pushState) {
        window.history.pushState(null, null, window.location.pathname);
    } else {
        window.location = window.location.pathname;
    }
};

// init
//
const RO_USERNAME = "usr_read_only";
const RO_USERPASS = "ROUdkdlofas8002";

function fireTracking(e) {
    ReactGA.pageview(window.location.pathname);
}
function sessionLogin(callback) {
    const sessionPromise = new Promise((resolve, reject) => {
        const session = getParam("session");
        if (session) {
            Parse.User.become(session)
                .then(sessionUser => {
                    userActions.updateUser(sessionUser);
                    resolve(true)
                })
                .catch(error => {
                    console.dir(error);
                    console.log("error: could not login from session token");
                    resolve(false);
                });
        } else {
            resolve(false);
        }
    });
    sessionPromise.then(fromSession => {
        const loginPromise = new Promise((resolve, reject) => {
            resetURL();
            let actUser = Parse.User.current();
            if (actUser == null) {
                Parse.User.logIn(RO_USERNAME, RO_USERPASS)
                    .then(user => {
                        Parse.User.enableRevocableSession()
                            .catch(error => {
                                if (error && error.code == 100) {
                                    console.log("CouldNotConnectToParse");
                                    notificationActions.notifyParseOffline()
                                } else if(error && error.code == 209) {
                                    console.log("ParseInvalidSession");
                                    notificationActions.notifyInvalidSession();
                                }
                            })
                            .finally(_=> {
                                resolve(user);
                            });
                    })
                    .catch(error => {n
                        console.log("error: could not login read-only user");
                        resetURL();
                        resolve(null);
                    });
            } else {
                if (RO_USERNAME == actUser.get("username")) {
                    userActions.updateUser(null);
                } else {
                    if (fromSession) {
                        // load as _User than I can use methods from own user class
                        let localUser = new User()
                        localUser.id = actUser.id
                        localUser.fetch()
                            .then(sUser => {
                                userActions.updateUser(sUser);
                            })
                    } else {
                        userActions.updateUser(actUser);
                    }
                }
                resolve(actUser);
            }
        });
        loginPromise.then(user => {
            userActions.fetchUserAbos(user);
            callback();
        })
    });
}

const MainContainer = () => {
    const [loading, setLoading] = React.useState("initialise user");
    React.useEffect(() => {
        sessionLogin(callback => {
            setLoading(null)
        })
    })
    return <AppContainer>
            <React.Fragment>
                {
                    loading ? <React.Fragment>
                        <Navbar inverse fixedTop expanded={false}>
                            <Navbar.Header>
                                <Navbar.Toggle style={{marginLeft:"10px"}} className="pull-left"/>
                                <Navbar.Brand>
                                    <Image style={{float: "left", margin:"-7px 0 0 0", padding: "0 0 0 0", width:"35px", height:"35px"}}
                                               src="/img/ico/ic_launcher_green.png"/>&nbsp;3DTurnier
                                </Navbar.Brand>
                            </Navbar.Header>
                        </Navbar>
                        <Grid>
                            <Row>
                                <br/><br/>
                                <Col xs={12} style={{"textAlign": "center"}}>
                                    <LoadingCircle/>
                                    <h4>{loading}</h4>
                                </Col>
                            </Row>
                        </Grid>
                    </React.Fragment>: <LanguageRouting/>
                }
                <CookieText />
            </React.Fragment>
        </AppContainer>
}
if (module.hot) {
    // module.hot.accept('./Routing.js', () => {
    //     const NexRouting = require('Routing');
    //     ReactDOM.render(NexRouting);
    // });
    module.hot.accept();
}
function LanguageRouting() {
    return <Router>
        <Switch>
            <Redirect from="/index.html" to={linkUtil.getLink("")}/>
            <Redirect from="/premium" to={linkUtil.getLink("skillboard/premium")}/>
            <Redirect from="/parcours/:id/home" to={linkUtil.getLink("parcours/:id/home")}/>
            <Redirect from="/3dturnierabo/:mgmtid" to={linkUtil.getLink("club/" + ConfigKeys.SKillBoardClubId + "/aboinvoice/:mgmtid")}/>
            <Route path="/:language"  component={LazyRouting} />
            <Redirect from="/" to={linkUtil.getLink("")}/>
        </Switch>
    </Router>
}
function LazyRouting({match}) {
    let language = match.params.language;
    let url = match.url;
    // console.log("LazyRouting ", match);
    return (<MenuWrapper language={language} pathname={url}>
                <Switch>
                    <Route exact path={url}><FrontPage /></Route>

                    <Route exact path={url + "/tournament"}><TournamentPage /></Route>
                    <Route path={url + "/tournament/rules/:bowUnionCode"}
                           component={({match}) => <TournamentRulePage bowUnionCode={match.params.bowUnionCode} />} />
                    <Route path={url + "/tournament/rules"} component={TournamentRulePage} />
                    <Route path={url + "/tournament/search/:key/:text"}
                           component={({match}) => <TournamentPage searchKey={match.params.key} searchText={match.params.text} />} />
                    <Route path={url + "/tournament/:id"}  component={RoutingTournament} />
                    <Route path={url + "/cup/:buCode/:licNumber"}
                           component={({match}) => <TournamentCupUserLinkPage buCode={match.params.buCode} licNumber={match.params.licNumber} />} />
                    <Route path={url + "/cup/:buCode"}
                           component={({match}) => <TournamentCupUserLinkPage buCode={match.params.buCode} licNumber={null}/>} />
                    <Route exact path={url + "/parcours/payment"}><ParcoursPaymentPage /></Route>
                    <Route exact path={url + "/parcours"}><ParcoursPage /></Route>
                    <Route exact path={url + "/parcours/display/list"}><ParcoursPage display={"LIST"} /></Route>
                    <Route path={url + "/parcours/:id"}  component={RoutingParcours} />

                    <Route path={url + "/club/event/:id/:secret"}
                           component={({match}) => <ClubEventPage eventID={match.params.id} secret={match.params.secret}/>} />
                    <Route path={url + "/club/event/:id"}
                           component={({match}) => <ClubEventPage eventID={match.params.id} />} />
                    <Route exact path={url + "/club"}><ClubPage /></Route>
                    <Route path={url + "/club/:id"}  component={RoutingClub} />

                    <Route path={url + "/transaction/:id"}  component={RoutingTransaction} />

                    <Route path={url + "/skillboard"} component={RoutingTabSkillBoard}/>
                    <Route path={url + "/profile"} component={RoutingTabProfile}/>
                    <Route path={url + "/trainingteam/:id"}
                           component={({match}) => <TrainingMainPage teamID={match.params.id} />} />
                    <Route path={url + "/trainingmember/:memberID"}
                           component={({match}) => <TrainingRemotePage memberID={match.params.memberID}/>} />
                    <Route path={url + "/trainingscatter/:filterID"}
                           component={({match}) => <TrainingScatterPage filterID={match.params.filterID}/>} />
                    <Route path={url + "/trainingscatter"} component={TrainingScatterPage}/>
                    <Route path={url + "/training"} component={TrainingMainPage}/>
                    <Route path={url + "/adminb"} component={RoutingTabAdminB}/>
                    <Route path={url + "/admind"} component={RoutingTabAdminData}/>

                    <Route exact path={url + "/mgmtparcours"}><ParcoursDashboardPage /></Route>
                    <Route path={url + "/mgmtparcours/:id/transactions"}
                           component={({match}) => <TransactionSettlementPage path="" managementID={match.params.id} />} />

                    <Route exact path={url + "/resetpass"}><ResetPasswordPage /></Route>
                    <Route path={url + "/resetpass/:action"}
                           component={({match}) => <ResetPasswordPage action={match.params.action} />} />


                    <Route exact path={url + "/targets"}><TargetCatalogPage /></Route>
                    <Route path={url + "/targets/:tstat"}
                           component={({match}) => <TargetCatalogPage tstat={match.params.tstat} />} />

                    <Route exact path={url + "/smarttournament"}><SmartTournamentPage /></Route>
                    <Route path={url + "/smarttournament/:id"}
                           component={({match}) => <SmartTournamentPage eventID={match.params.id} />} />

                    <Route exact path={url + "/settlement/:id"}
                           component={({match}) => <SettlementDetailPage id={match.params.id} />} />
                    <Route path={url + "/settlement/:id/:tstat"}
                           component={({match}) => <SettlementDetailPage id={match.params.id} tstat={match.params.tstat}/>} />

                    <Route path={url + "/userToken/:id"}
                           component={({match}) => <UserTokenPage tokenID={match.params.id} />} />


                    <Route exact path={url + "/customer/register"}><BusinessRegisterPage /></Route>
                    <Route path={url + "/customer/register/club/:clubID"}
                           component={({match}) => <BusinessRegisterPage clubID={match.params.clubID} />} />

                    <Route path={url + "/news"}><NewsPage /></Route>
                    <Route path={url + "/account"}><AccountPage /></Route>
                    <Route path={url + "/translate"}><TranslatorPage /></Route>
                    <Route path={url + "/solutions"}><BusinessPromoPage /></Route>
                    <Route path={url + "/contact"}><ContactPage /></Route>
                    <Route path={url + "/policy"}><PolicyPage /></Route>
                    <Route path={url + "/impressum"}><Impressum /></Route>
                    <Route path={url + "/agb"}><AgbPage /></Route>
                    <Route path="*"><NotFound path={url} /></Route>
                    <Route path="404"><NotFound path={url} /></Route>
                </Switch>
            </MenuWrapper>
    )
}
function RoutingTournament({match}) {
    let id = match.params.id;
    let url = match.url;
    // console.log("RoutingTournament ", match);
    return <Switch>
        <Route exact path={url}><TournamentDetailsPage tournamentId={id} /></Route>
        <Route path={url + "/private/:secret"}
               component={({match}) => <TournamentDetailsPage tournamentId={id} secret={match.params.secret} />} />
        <Route path={url + "/admin"}><TournamentAdminPage tournamentId={id} /></Route>
        <Route path={url + "/live"}><TournamentLivePage tournamentId={id} /></Route>
        <Route path={url + "/groups"}><TournamentLiveGroupPage tournamentId={id} /></Route>
        <Route path={url + "/checkout"}><TournamentCheckoutPage tournamentId={id} /></Route>

        <Route path={url + "/manager"}><TournamentManagerPage tournamentId={id} /></Route>
        <Route path={url + "/mmfile"}><TournamentMMFilePage tournamentId={id} /></Route>
        <Route path={url + "/mmround"}><TournamentMMRoundPage tournamentId={id} /></Route>
        <Route path={url + "/mmconfig"}><TournamentMMConfigPage tournamentId={id} /></Route>
        <Route path={url + "/mmtuser"}><TournamentMMTUserPage tournamentId={id} /></Route>
        <Route path={url + "/mmtcorrect"}><TournamentMMTCorrectPage tournamentId={id} /></Route>
        <Route path={url + "/mmscan"}><TournamentMMScanPage tournamentId={id} /></Route>
        <Route path={url + "/register"}><TournamentRegistrationPage tournamentId={id} /></Route>
        <Route path={url + "/gallery"}><ImageGalleryPage type="Tournament" id={id} /></Route>
        <Route path={url + "/self"}><TournamentDetailsPage tournamentId={id} /></Route>
        <Route path={url + "/foreign/:name/:mail"}><TournamentDetailsPage tournamentId={id} /></Route>
        <Route path="*"><NotFound path={url} /></Route>
    </Switch>
}
function RoutingParcours({match}) {
    let id = match.params.id;
    let url = match.url;
    // console.log("RoutingParcours ", match);
    return <Switch>
        <Route exact path={url}><ParcoursDetailPage onlineID={id} /></Route>
        <Route path={url + "/admin"}><ParcoursAdminPage onlineID={id} /></Route>
        <Route exact path={url + "/edit"}><ParcoursEditPage onlineID={id} /></Route>
        <Route path={url + "/edit/:tstat"}
               component={({match}) => <ParcoursEditPage onlineID={id} tstat={match.params.tstat} />} />
        <Route path={url + "/checkout"}><ParcoursCheckoutPage onlineID={id} /></Route>
        <Route exact path={url + "/ranking"}><ParcoursRankingPage onlineID={id} /></Route>
        <Route path={url + "/ranking/:year/:month"}
               component={({match}) => <ParcoursRankingPage onlineID={id} year={match.params.year} month={match.params.month}/>} />

        <Route exact path={url + "/targets"}><ParcoursTargetPage onlineID={id} /></Route>
        <Route exact path={url + "/targets/tracks"}><ParcoursTargetTrackPage onlineID={id} /></Route>
        <Route path={url + "/targets/tracks/:tstat"}
               component={({match}) => <ParcoursTargetTrackPage onlineID={id} tstat={match.params.tstat} />} />

        <Route exact path={url + "/visit"}><ParcoursVisitPage onlineID={id} /></Route>
        <Route path={url + "/visit/:visitID"}
               component={({match}) => <ParcoursVisitPage onlineID={id} visitID={match.params.visitID} />} />

        <Route exact path={url + "/targets/edit"}><ParcoursTargetEditPage onlineID={id} /></Route>

        <Route path={url + "/targets/edit/:trackID/:tstat"}
               component={({match}) => <ParcoursTargetEditPage onlineID={id} trackID={match.params.trackID} tstat={match.params.tstat}/>} />

        <Route path={url + "/targets/edit/:trackID"}
               component={({match}) => <ParcoursTargetEditPage onlineID={id} trackID={match.params.trackID} tstat={match.params.trackID} />} />


        <Route path={url + "/articles"}><ParcoursArticlePage ownerID={id} /></Route>
        <Route path={url + "/visits"}><ParcoursVisitListPage ownerID={id} /></Route>
        <Route path={url + "/manage"}><ParcoursManageRequestPage ownerID={id} /></Route>
        <Route path={url + "/home"}><ParcoursLandingPage ownerID={id} /></Route>

        <Route exact path={url + "/stats"}><ParcoursStatisticPage onlineID={id} /></Route>
        <Route path={url + "/stats/:tstat"}
               component={({match}) => <ParcoursStatisticPage onlineID={id} tstat={match.params.tstat} />} />

        <Route path={url + "/gallery"}><ImageGalleryPage type="Parcours" id={id} /></Route>
        <Route path="*"><NotFound path={url}/></Route>
    </Switch>
}
function RoutingClub({match}) {
    let id = match.params.id;
    let url = match.url;
    // console.log("RoutingClub ", match);
    return <Switch>
        <Route exact path={url}><ClubDetailsPage clubID={id} /></Route>
        <Route exact path={url + "/members"}><ClubUsersPage clubID={id} /></Route>
        <Route path={url + "/members/:cuid"}
               component={({match}) => <ClubUserDetailPage clubID={id} clubUserID={match.params.cuid} />} />
        <Route path={url + "/accounting"}><ClubAccountingPage clubID={id} /></Route>
        <Route path={url + "/edit"}><ClubSettingsPage clubID={id} /></Route>
        <Route path={url + "/invoice"}><ClubInvoicePage clubID={id} /></Route>
        <Route path={url + "/aboinvoice/:mgmtid"}
               component={({match}) => <ClubAboInvoicePage clubID={id} mgmtid={match.params.mgmtid} />} />
        <Route path={url + "/payinvoice/:invid"}
               component={({match}) => <ClubInvoiceCheckoutPage clubID={id} invid={match.params.invid} />} />
        <Route path={url + "/articles/:group"}
               component={({match}) => <ClubArticlePage clubID={id} group={match.params.group} />} />
        <Route path="*"><NotFound path={url}/></Route>
    </Switch>
}
function RoutingTransaction({match}) {
    let id = match.params.id;
    let url = match.url;
    // console.log("RoutingTransaction ", match);
    return <Switch>
        <Route exact path={url}><TransactionDetailPage transactionID={id} /></Route>
        <Route exact path={url + "/:tstat"}
               component={({match}) => <TransactionDetailPage transactionID={id} tstat={match.params.tstat} />} />
    </Switch>
}
function RoutingTabAdminB({match, location}) {
    let url = match.url;
    let path = location.pathname;
    // console.log("RoutingTabAdminB ", match, location);
    return <Switch>
        <Route exact path={url + "/business/:id/transactions"}
               component={({match}) => <TransactionSettlementPage path={path} managementID={match.params.id} />} />
        <Route path={url + "/business/:id/transactions/:year/:month"}
               component={({match}) => <TransactionSettlementPage path={path} managementID={match.params.id} year={match.params.year} month={match.params.month}/>} />
        <AdminTabPage path={path} type="business">
            <RouteSuspense text="AdminBusiness">
                <Route exact path={url + "/business"}><AdminBusiness/></Route>
                <Route path={url + "/settle"}><AdminBusinessSettle/></Route>
                <Route path={url + "/request"}><AdminBusinessRequest/></Route>
                <Route path={url + "/poi"}><AdminPointInterest/></Route>
                <Route path={url + "/toptions"}><AdminTournamentOptions/></Route>
                <Route path={url + "/voucher"}><AdminVoucher/></Route>
                <Route paht={url + "/handler"}><AdminBusinessHandler /></Route>
            </RouteSuspense>
        </AdminTabPage>
        </Switch>

}
function RoutingTabAdminData({match, location}) {
    let url = match.url;
    let path = location.pathname;
    // console.log("RoutingTabAdminData ", match);
    return <AdminTabPage path={path} type="data">
        <Switch>
            <RouteSuspense text="AdminData">
                <Route exact path={url + "/changelog"}><AdminChangeLog/></Route>
                <Route path={url + "/changelog/:id"}
                       component={({match}) => <AdminChangeLog onlineID={match.params.id} />} />
                <Route exact path={url + "/user"}><AdminUserSearch/></Route>
                <Route path={url + "/user/:searchParam"}
                       component={({match}) => <AdminUserSearch searchParam={match.params.searchParam} />} />
                <Route path={url + "/exchangeuser"}><AdminUserExchanger/></Route>
                <Route path={url + "/exchangeparcours"}><AdminParcoursExchanger/></Route>
                <Route path={url + "/data"}><AdminDataHandler/></Route>
                <Route path={url + "/statistics"}><AdminStatisticHandler/></Route>
                <Route path={url + "/release"}><AdminRelease/></Route>
                <Route path={url + "/logs"}><AdminParseLog/></Route>
            </RouteSuspense>
        </Switch>
    </AdminTabPage>
}
function RoutingTabSkillBoard({match, location}) {
    let url = match.url;
    let path = location.pathname;
    // console.log("RoutingTabSkillBoard ", match, location);
    return (<Switch>
        <Route exact path={url + "/premium"}><SkillBoardProPage/></Route>
        <Route exact path={url + "/premium/buy"}><SkillBoardProBuyPage/></Route>
        <Route path={url + "/premium/buy/idx/:index"}
               component={({match}) => <SkillBoardProBuyPage index={match.params.index} />} />
        <Route path={url + "/premium/buy/:voucherID"}
               component={({match}) => <SkillBoardProBuyPage voucherID={match.params.voucherID} />} />
        <SkillBoardPage path={path}>
            <Route exact path={url}><GettingStarted/></Route>
            <Route path={url + "/device/:device"}><GettingStarted/></Route>
            <Route path={url + "/tab/jointournament"}><JoinTournament/></Route>
            <Route path={url + "/tab/faq"}><FAQs/></Route>
            <Route exact path={url + "/tab/scores"}><CountTypesTab/></Route>
            <Route path={url + "/tab/scores/:tstat"}
                   component={({match}) => <CountTypesTab tstat={match.params.tstat} />} />
            <Route path={url + "/tab/releasenotes"}><ReleaseNotes/></Route>
            <Route path={url + "/tab/videos"}><YTVideos/></Route>
        </SkillBoardPage>
    </Switch>)
}
function RoutingTabProfile({match, location}) {
    let url = match.url;
    let path = location.pathname;
    // console.log("RoutingTabProfile ", match, location);
    return(<Switch>
        <Route exact path={url + "/eventdetail/:id"} component={({match}) => <EventDetailPage eventID={match.params.id} />} />
        <Route path={url + "/eventdetail/:id/:tstat"} component={({match}) => <EventDetailPage eventID={match.params.id} tstat={match.params.tstat}/>} />


        <Route exact path={url}><ProfileTabPage path={path} year={match.params.year}><TabInformation/></ProfileTabPage></Route>
        <Route path={url + "/tournaments/:year"}
               component={({match}) => <ProfileTabPage path={path} year={match.params.year}><TabTournament year={match.params.year} /></ProfileTabPage>} />
        <Route path={url + "/events/:year"}
               component={({match}) => <ProfileTabPage path={path} year={match.params.year}><TabEventList year={match.params.year} /></ProfileTabPage>} />
        <Route path={url + "/scatter/:year"}
               component={({match}) => <ProfileTabPage path={path} year={match.params.year}><TabEventScatter year={match.params.year} /></ProfileTabPage>} />
        <Route path={url + "/statistics/:year"}
               component={({match}) => <ProfileTabPage path={path} year={match.params.year}><TabEventStatistic year={match.params.year} /></ProfileTabPage>} />
        <Route path={url + "/invoices/:year"}
               component={({match}) => <ProfileTabPage path={path} year={match.params.year}><TabTransaction year={match.params.year} /></ProfileTabPage>} />
    </Switch>)
}
class RouteSuspense extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            errorInfo: null
        }
    }
    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo
        });
        console.log("RouteSuspenseError: ", error, errorInfo);
    }
    render() {
        const {error} = this.state;
        const {children, text} = this.props;
        if (error) {
            return <FontAwesome icon="bug">{error.toString()}</FontAwesome>
        } else {
            return <React.Suspense fallback={<div><LoadingCircle/> {text}</div>}>
                {children}
            </React.Suspense>
        }
    }
}
const CookieText = () => {
    return <CookieConsent acceptOnScroll={true} buttonStyle={{ marginRight: "100px" }}
        cookieName="3dskb_accept_cookie"
        buttonText="Okay">
        Diese Webseite nutzt Technologien wie Cookies um: ...ihre Session zu behalten, ...mit uns chatten zu können, ...für den Webshop-Einkaufskorb und wir verwenden verschiedene Google Dienste (Analytics um die Zugriffe zu analysieren, Maps, Fonts, ..). Sie geben dazu ihre Einwilligung, wenn Sie unsere Webseite weiterhin nutzen.
    </CookieConsent>
};
ReactDOM.render(<MainContainer />, document.getElementById('react-client-root'));