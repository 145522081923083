const Parse = require("ParseInit");

const Event = Parse.Object.extend("Event", {
    col: {
        ACL: "ACL",
        accessCode: "accessCode",
        countTypeOnlineID: "countTypeOnlineID",
        createdAt: "createdAt",
        date: "date",
        editor: "editor",
        insstmp: "insstmp",
        duration: "duration",
        name: "name",
        trackID: "trackID",
        objectId: "objectId",
        parcoursID: "parcoursID",
        parcoursOnlineID: "parcoursOnlineID",
        status: "status",
        visStatus: "visStatus",
        tournamentID: "tournamentID",
        tournamentGroupID: "tournamentGroupID",
        tournamentRoundID: "tournamentRoundID",
        type: "type",
        tag: "tag",
        updatedAt: "updatedAt",
    },
    status: {
        active: "A",
        deleted: "D",
        closed: "C"
    },
    visible: {
        visible: "V",
        invisible: "I"
    },
    eventType: {
        normal: "N",
        tournament: "T",
        smart: "S",
        training3d: "D",
        trainingField: "F",
        trainingOutdoor: "O",
        trainingIndoor: "I",

    },
    isDeleted() {
        return this.status.deleted === this.get('status');
    },
    isEditor(user) {
        let result = false;
        if (this.getEditor() && user) {
            if (user.id == this.getEditor().id) {
                result = true;
            }
        }
        return result;
    },
    isNormalEvent() {
        return this.eventType.normal === this.getType();
    },
    isTournament() {
        return this.eventType.tournament === this.getType();
    },
    isAnyTraining() {
        return this.eventType.training3d === this.getType()
            || this.eventType.trainingField === this.getType()
            || this.eventType.trainingOutdoor === this.getType()
            || this.eventType.trainingIndoor === this.getType()
    },
    isRankingVisible() {
        if (this.getVisibileStatus()) {
            return this.visible.visible === this.getVisibileStatus();
        }
        // true by default
        return true;
    },
    getACL() {
        return this.get(this.col.ACL)
    },
    setACL(ACL) {
        return this.set(this.col.ACL, ACL)
    },
    getAccessCode() {
        return this.get(this.col.accessCode)
    },
    setAccessCode(accessCode) {
        return this.set(this.col.accessCode, accessCode)
    },
    getCountTypeOnlineID() {
        return this.get(this.col.countTypeOnlineID)
    },
    setCountTypeOnlineID(countTypeOnlineID) {
        return this.set(this.col.countTypeOnlineID, countTypeOnlineID)
    },
    getCreatedAt() {
        return this.get(this.col.createdAt)
    },
    getDate() {
        return this.get(this.col.date)
    },
    setDate(date) {
        return this.set(this.col.date, date)
    },
    getEventInsstmpDate() {
        return new Date(this.getInsstmp());
    },
    getEditor() {
        return this.get(this.col.editor)
    },
    setEditor(editor) {
        return this.set(this.col.editor, editor)
    },
    getInsstmp() {
        return this.get(this.col.insstmp)
    },
    setInsstmp(insstmp) {
        return this.set(this.col.insstmp, insstmp)
    },
    getDuration() {
        return this.get(this.col.duration)
    },
    setDuration(duration) {
        return this.set(this.col.duration, duration)
    },
    getName() {
        return this.get(this.col.name)
    },
    setName(name) {
        return this.set(this.col.name, name)
    },
    getObjectId() {
        return this.get(this.col.objectId)
    },
    setObjectId(objectId) {
        return this.set(this.col.objectId, objectId)
    },
    getParcoursID() {
        return this.get(this.col.parcoursID)
    },
    setParcoursID(parcoursID) {
        return this.set(this.col.parcoursID, parcoursID)
    },
    getParcoursOnlineID() {
        return this.get(this.col.parcoursOnlineID)
    },
    setParcoursOnlineID(parcoursOnlineID) {
        return this.set(this.col.parcoursOnlineID, parcoursOnlineID)
    },
    getStatus() {
        return this.get(this.col.status)
    },
    setStatus(status) {
        return this.set(this.col.status, status)
    },
    getVisibileStatus() {
        return this.get(this.col.visStatus)
    },
    setVisibileStatus(status) {
        return this.set(this.col.visStatus, status)
    },
    getTournamentID() {
        return this.get(this.col.tournamentID)
    },
    setTournamentID(tournamentID) {
        return this.set(this.col.tournamentID, tournamentID)
    },
    getTrackID() {
        return this.get(this.col.trackID)
    },
    getTournamentRoundID() {
        return this.get(this.col.tournamentRoundID)
    },
    setTournamentRoundID(tournamentRoundID) {
        return this.set(this.col.tournamentRoundID, tournamentRoundID)
    },
    getType() {
        return this.get(this.col.type)
    },
    getTypeTranslationCode() {
        const type = this.getType();
        if (this.eventType.normal === type) {
            return "training_mode_no";
        } else if (this.eventType.tournament === type) {
            return "training_mode_tournament";
        } else if (this.eventType.training3d === type) {
            return "training_mode_3d";
        } else if (this.eventType.trainingField === type) {
            return "training_mode_field";
        } else if (this.eventType.trainingOutdoor === type) {
            return "training_mode_outdoor";
        } else if (this.eventType.trainingIndoor === type) {
            return "training_mode_indoor";
        }
    },
    getTagArray() {
        return this.get(this.col.tag)
    },
    setType(type) {
        return this.set(this.col.type, type)
    },
    getUpdatedAt() {
        return this.get(this.col.updatedAt)
    },
});

module.exports = Event;
