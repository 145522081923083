const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Button, Table} = require("react-bootstrap");

const FontAwesome = require("components/widgets/FontAwesome"),
    I18n = require("components/widgets/I18n"),
    TooltipButton  = require("components/form/TooltipButton"),
    SubmitButton = require("components/form/SubmitButton"),
    messages = require("i18n/messages");

const TournamentCupTable = require("components/tournament/TournamentCupTable");
const TournamentResultGrouping = require("components/tournament/TournamentResultGrouping");

const tournamentUserManagerStore = require("stores/TournamentUserManagerStore"),
    tournamentUserManagerActions = require("actions/TournamentUserManagerActions");

class TournamentCupManager extends React.Component {
    constructor(props) {
        super(props);
        this.refSubmitCalcButton = React.createRef();
        this.refSubmitInterCalcButton = React.createRef();
        this.refSubmitSaveButton = React.createRef();
        this.refSubmitDelButton = React.createRef();

        this.handleTournamentUserChange = this.handleTournamentUserChange.bind(this);
        this.handleCalculateTournamentCupResult = this.handleCalculateTournamentCupResult.bind(this);
        this.validateTournamentCupResult = this.validateTournamentCupResult.bind(this);
        this.handleSaveTournamentCupResult = this.handleSaveTournamentCupResult.bind(this);
        this.handleDeleteTournamentCup = this.handleDeleteTournamentCup.bind(this);

        this.state = {
            tournamentUserManagerStore: tournamentUserManagerStore.getState(),
            tUserPairs: []
        }
    }
    componentDidMount() {
        tournamentUserManagerStore.listen(this.handleTournamentUserChange);
        //tournamentUserManagerActions.loadTournamentUsers.defer(this.props.tournament, this.state.showDeletedUsers)
    }
    componentWillUnmount() {
        tournamentUserManagerActions.resetTournamentUsers();
        tournamentUserManagerStore.unlisten(this.handleTournamentUserChange);
    }
    handleTournamentUserChange(state) {
        this.setState({tournamentUserManagerStore: state});
    }
    handleCalculateTournamentCupResult(finalResult) {
        tournamentUserManagerActions.calcTournamentCupResult(this.props.tournament, this.props.childTournaments, finalResult, (result) => {
            this.setState({tUserPairs: []});
            this.refSubmitCalcButton.current.resetLoading();
            this.refSubmitInterCalcButton.current.resetLoading();
        });
    }
    validateTournamentCupResult() {
        if (this.state.tournamentUserManagerStore.tournamentUserCupResult) {
            this.state.tournamentUserManagerStore.tournamentUserCupResult.validateGroupedList(callbackTUserPair => {
                let pairs = this.state.tUserPairs;
                pairs.push(callbackTUserPair);
                this.setState({tUserPairs: pairs});
            })
        }
    }
    handleSaveTournamentCupResult() {
        tournamentUserManagerActions.saveTournamentCupResult(this.props.tournament, this.state.tournamentUserManagerStore.tournamentUserCupResult, (result) => {
            this.refSubmitSaveButton.current.resetLoading();
        });
    }
    handleDeleteTournamentCup() {
        tournamentUserManagerActions.deleteCupResult(this.props.tournament, (result) => {
            this.refSubmitDelButton.current.resetLoading();
        })
    }
    render() {
        const {user, tournament, childTournaments} = this.props;
        return (<Row bsStyle="info">
            <Col xs={12}>
                <h3><I18n code="tournament.info.cupChilds"/></h3>
                <TournamentCupTable tournament={tournament}
                                    childTournaments={childTournaments}/>
            </Col>
            <Col xs={12}>
                <SubmitButton ref={this.refSubmitInterCalcButton}
                              disabled={childTournaments == null || childTournaments.length == 0}
                              onClick={() => this.handleCalculateTournamentCupResult(false)}
                              icon={<FontAwesome icon="cogs"/>}
                              text={messages.get("tournament.manager.cup.intermediate")}
                              bsStyle="primary"/>
                <SubmitButton ref={this.refSubmitCalcButton}
                              disabled={childTournaments == null || childTournaments.length == 0}
                              onClick={() => this.handleCalculateTournamentCupResult(true)}
                              icon={<FontAwesome icon="cogs"/>}
                              text={messages.get("tournament.manager.cup.result")}
                              bsStyle="primary"/>
                <SubmitButton ref={this.refSubmitDelButton}
                              onClick={() => this.handleDeleteTournamentCup()}
                              icon={<FontAwesome icon="trash"/>}
                              text={messages.get("tournament.manager.cup.result")}
                              bsStyle="primary"/>
                {
                    this.state.tournamentUserManagerStore.tournamentUserCupResult != null && this.state.tUserPairs.length <= 0 ?
                        <SubmitButton ref={this.refSubmitSaveButton}
                                      disabled={false}
                                      onClick={this.handleSaveTournamentCupResult}
                                      icon={<FontAwesome icon="save"/>}
                                      text={messages.get("tournament.manager.cup.result")}
                                      bsStyle="primary"/>
                        : null
                }
            </Col>
            <Col xs={12}>
                <dl>
                    <dt><I18n code="tournament.table.column.cupPoints"/>:&nbsp;</dt>
                    <dd>Summe der höchsten Cup-Punkte der  #MindestAnzahl Turniere</dd>
                    <dt>20/11er:&nbsp;</dt>
                    <dd>Summe der höchsten Wertungszone aller Turniere</dd>
                    <dt><I18n code="tournament.table.column.points"/>:&nbsp;</dt>
                    <dd>Summe der erzielten Punkte gereiht nach den höchsten Cup-Punkte der #MindestAnzahl Turniere</dd>

                </dl>
            </Col>
            <Col xs={12}>
                {
                    this.state.tournamentUserManagerStore.tournamentUserCupResult ?

                            <TooltipButton onClick={this.validateTournamentCupResult} icon="check" bsStyle="primary"
                                           tooltip={"Vergleicht Namen der Teilnehmer innerhalb einer Bogen- und Altersklasse"}>
                                Teilnehmercheck
                            </TooltipButton>

                        : null
                }
                {
                    this.state.tUserPairs.length > 0 ? <TUserPairTable tUserPairs={this.state.tUserPairs} childTournaments={childTournaments}/> : null
                }
            </Col>
            {
                this.state.tournamentUserManagerStore.tournamentUserCupResult ?
                    <TournamentResultGrouping showHighestZoneCounter={true}
                        liveModus={false}
                        tournamentRoundMap={{qualify: [], finale: [], group: [], extra: []}}
                        tournamentUserResult={this.state.tournamentUserManagerStore.tournamentUserCupResult}
                        tournament={tournament}
                        user={user}/>: null
            }
        </Row>)
    }
}
TournamentCupManager.propTypes = {
    user:PropTypes.object.isRequired,
    tournament:PropTypes.object.isRequired,
    childTournaments:PropTypes.array.isRequired
};
const TUserPairTable = ({tUserPairs, childTournaments}) => {
    return <Table>
        <thead>
        <tr>
            <th>Name</th>
            <th>Lic1</th>
            <th>Lic2</th>
        </tr>
        </thead>
        <tbody>
        {
            tUserPairs.map(userPair => {
                return <TUserPairEntry key={userPair[0].id} stTUser={userPair[0]} ndTUser={userPair[1]} childTournaments={childTournaments}/>
            })
        }
        </tbody>
    </Table>
};
const TUserPairEntry = ({childTournaments, stTUser, ndTUser}) => {
    function updateWrongTUsers(correctTUser) {
        tournamentUserManagerActions.updateWrongTUsers(correctTUser, childTournaments)
    }
    return <tr>
        <td>{stTUser.getFullName()}</td>
        <td><Button onClick={() => updateWrongTUsers(stTUser)}>{"use " + stTUser.getLicenseNumber()}<br/>
                <span>{stTUser.getAllCupPoints() ? stTUser.getAllCupPoints().join(" | ") : ""}</span>
        </Button></td>
        <td><Button onClick={() => updateWrongTUsers(ndTUser)}>{"use " + ndTUser.getLicenseNumber()}<br/>
            <span>{ndTUser.getAllCupPoints() ? ndTUser.getAllCupPoints().join(" | ") : ""}</span>
        </Button></td>
    </tr>
};
module.exports = TournamentCupManager;
